.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #34495e; /* Slightly different color from the main background */
  color: #ecf0f1;
  text-align: center;
  padding: 10px 0;
  margin: 0; /* Ensure no margin */
  left: 0; /* Ensure footer is aligned to the left */
  right: 0; /* Ensure footer is aligned to the right */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  z-index: 1000; /* Ensure footer is on top of other elements */
}

.links-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0; /* Ensure no margin */
  padding: 0; /* Ensure no padding */
  width: 100%; /* Ensure full width */
}

.links-container p {
  margin: 0;
}

.btn-link {
  color: #ecf0f1;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.btn-link:hover {
  text-decoration: underline;
}

.social-icon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

/* Media query to hide text on smaller screens */
@media (max-width: 576px) {
  .btn-link {
    flex-direction: column;
  }

  .btn-link span {
    display: none;
  }

  .social-icon {
    margin-right: 0;
  }
}
