* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
}

.App {
  font-family: Arial, sans-serif;
  text-align: center;
  background-color: #2c3e50;
  color: #ecf0f1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  position: relative;
  padding-bottom: 100px;
}

.header {
  font-size: 2em;
  margin-bottom: 1px;
  font-weight: bold;
}

.header a {
  color: inherit;
  text-decoration: none;
  transition: text-decoration 0.3s ease;
}

.header a:hover {
  text-decoration: underline;
}


.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
}

.trait-selection, .art-display-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #34495e;
  border-radius: 10px;
  padding: 20px;
  width: 450px;
  position: relative;
  margin: 10px;
  min-height: 500px;
}

.art-display-container {
  height: auto;
}

.info-button {
  position: absolute;
  top: -5px;
  right: 10px;
  z-index: 10;
}

.traits-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr); 
  grid-gap: 5px; 
  width: 100%; 
  padding: 0 5px; 
  box-sizing: border-box;
}

.trait {
  width: 100%; 
  aspect-ratio: 1 / 1;
  position: relative;
  border: 2px solid #2980b9;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.trait img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.trait:hover {
  border-color: #3498db;
}

.art-display-wrapper {
  position: relative;
  width: 400px;
  height: 400px;
  background: transparent;
  border: 2px solid #34495e;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
}

.art-display img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.match-message {
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1em;
  color: #ecf0f1;
  margin-top: 10px;
}

.divider {
  width: 100%;
  height: 2px;
  background-color: #ecf0f1;
  margin: 20px 0;
  border: none;
  box-sizing: border-box;
}

.additional-controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0;
  align-items: center;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-container label {
  font-weight: bold;
  font-size: 1em;
  margin-bottom: 5px;
}

.input-container input {
  padding: 5px;
  font-size: 1em;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 150px;
  background-color: #2c3e50;
  color: #ecf0f1;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
  margin-top: 14px;
  flex-wrap: wrap;
}

button, .rules-modal button {
  background-color: transparent;
  color: white;
  border: 2px solid white;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 5px;
}

button:hover, .rules-modal button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: white;
}

.pallet-button, .curated-button {
  display: inline-block;
  background-color: #fa5d3d;
  color: white;
  padding: 10px 20px;
  margin-top: 5px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1.1em;
  cursor: pointer;
  border: none;
}

.pallet-button:hover, .curated-button:hover {
  background-color: #e04e2e;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #34495e;
  color: #ecf0f1;
  text-align: center;
  padding: 10px 0;
}

.bottom-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 700px;
  background-color: #34495e;
  padding: 20px;
  border-radius: 10px;
  margin: 0 auto;
}

.links-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0;
  padding: 0;
}

.links-container p {
  margin: 0 10px;
}

.btn-link {
  color: #ecf0f1;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.btn-link:hover {
  text-decoration: underline;
}

.social-icon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.rules-modal {
  background-color: #34495e;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 80%;
  text-align: left;
  color: #ecf0f1;
}

.rules-modal h2 {
  margin-top: 0;
}

.rules-modal ul {
  list-style-type: none;
  padding-left: 0;
}

.rules-modal li {
  margin-bottom: 10px;
}

.rules-link {
  color: #ffffff;
  text-decoration: none;
  margin-top: 10px;
  display: block;
  text-align: center;
}

.rules-link:hover {
  text-decoration: underline;
}

.art-display {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 275px;
  height: 275px;
  margin-bottom: 20px;
  position: relative;
}

.token-id {
  margin-bottom: 10px;
  color: #27ae60;
}

.token-input {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  width: 100%;
  gap: 10px;
}

.token-input label {
  font-weight: bold;
  font-size: 1em;
  color: #ecf0f1;
}

.token-input input {
  padding: 5px;
  font-size: 1em;
  text-align: center;
  border-radius: 5px;
  background-color: transparent;
  color: white;
  border: 2px solid white;
  width: 100px;
  box-sizing: border-box;
}

.selected-traits {
  margin-top: 20px;
  text-align: center;
  color: #ecf0f1;
  height: 286px; /* Hardcoded height */
  overflow-y: auto; /* Add scroll if content overflows */
}

.selected-traits h3 {
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.traits-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  gap: 5px;
  width: 100%;
}

.trait-item {
  background-color: #2c3e50;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  color: #ecf0f1;
}

.tabs {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.tab-button {
  margin: 5px;
  padding: 8px 12px;
  min-width: 80px;
  background-color: #2c3e50;
  color: #fff;
  border: 2px solid transparent;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.active-tab {
  background-color: rgba(255, 255, 255, 0.2);
  border-color: #3498db;
}

.tab-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: white;
}

.traits-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 5px;
  width: 100%;
  padding: 0; 
  margin: 0 auto;
}

.traits-grid .trait {
  cursor: pointer;
  border: 2px solid transparent;
}

.traits-grid .trait:hover {
  border-color: #2980b9;
}
